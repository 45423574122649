<template>
  <v-row>
    <v-col cols="6">
      <v-select
        placeholder="請選擇"
        v-model="select1"
        :items="actOptions"
        dense
        hide-details
        disabled
      ></v-select>
    </v-col>
    <v-col cols="6" v-if="select1 && select1 === 'relay'">
      <v-select
        placeholder="請選擇"
        v-model="event.relaymode"
        :items="relayActionOption"
        item-text="name"
        item-value="acResponseValue"
        dense
        hide-details
      ></v-select>
    </v-col>
    <v-col cols="6" v-if="select1 && select1 === 'POWER_ON'">
      <v-select
        placeholder="請選擇"
        v-model="event.operationmode"
        :items="modeOption"
        item-text="text"
        item-value="acResponseValue"
        dense
        hide-details
      ></v-select>
    </v-col>
    <v-col
      cols="6"
      v-if="
        (select1 && select1 === 'POWER_ON' && event.operationmode === 1) ||
        (select1 && select1 === 'temperature')
      "
    >
      <v-select
        placeholder="請選擇"
        v-model="event.temperature"
        :items="temperatureOption"
        item-value="acResponseValue"
        dense
        hide-details
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CalendarEventAction',
  mixins: [],
  components: {},
  props: {
    value: {
      type: Object
    }
  },
  data: function () {
    return {
      event: {
        airconditioningstatus: null,
        operationmode: null,
        temperature: null,
        relaymode: null
      },
      select1: null,
      actOptions: [
        // {
        //   text: '冷氣開',
        //   value: 'POWER_ON'
        // },
        // {
        //   text: '冷氣關',
        //   value: 'POWER_OFF'
        // },
        {
          text: '電驛控制',
          value: 'relay'
        },
        {
          text: '溫度控制',
          value: 'temperature'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      relayActionOption: 'ac/relayActionOption',
      modeOption: 'ac/modeOption',
      temperatureOption: 'ac/temperatureOption',
      fanOption: 'ac/fanOption'
    }),
    temperatureOption() {
      console.log(this.$store)
      return this.$store.getters['ac/temperatureOption'].map((item) => {
        item.acResponseValue = Number(item.acResponseValue)
        return item
      })
    }
  },
  watch: {
    value() {
      this.initSelect1()
    },
    select1(val) {
      this.commit()
    },
    event: {
      deep: true,
      handler() {
        this.commit()
      }
    }
  },
  created: function () {},
  mounted: function () {
    this.initSelect1()
  },
  destroyed() {},
  methods: {
    initSelect1() {
      if (!this.event || !this.value) {
        this.select1 = null
        return
      }

      this.event.airconditioningstatus = this.value.airconditioningstatus
      this.event.operationmode = this.value.operationmode
      this.event.temperature = this.value.temperature
      this.event.relaymode = this.value.relaymode

      // 溫度控制
      if (
        typeof this.event.airconditioningstatus === 'undefined' &&
        typeof this.event.operationmode === 'undefined' &&
        typeof this.event.relaymode === 'undefined'
      ) {
        this.select1 = 'temperature'
        return
      }

      // 電驛控制
      if (typeof this.event.airconditioningstatus === 'undefined') {
        this.select1 = 'relay'
        return
      }

      // 冷氣開關 0:off, 1:on
      this.select1 =
        this.event.airconditioningstatus &&
        this.event.airconditioningstatus === 1
          ? 'POWER_ON'
          : 'POWER_OFF'
    },
    commit() {
      let actionData = {}
      if (this.select1 === 'relay') {
        actionData = {
          relaymode: this.event.relaymode || 0
        }
      } else if (this.select1 === 'temperature') {
        actionData = {
          temperature: this.event.temperature || 28
        }
      } else if (this.select1 === 'POWER_OFF') {
        actionData = {
          relaymode: 0,
          airconditioningstatus: 0
        }
      } else if (
        this.select1 === 'POWER_ON' &&
        this.event.operationmode === 1
      ) {
        // 冷氣
        actionData = {
          relaymode: 1,
          airconditioningstatus: 1,
          operationmode: 1,
          temperature: this.event.temperature || 28
        }
      } else if (this.select1 === 'POWER_ON') {
        // 除溼、送風
        actionData = {
          relaymode: 1,
          airconditioningstatus: 1,
          operationmode: this.event.operationmode || 0
        }
      }
      this.$emit('input', actionData)
    }
  }
}
</script>

<style lang="scss" scoped></style>
