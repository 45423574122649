var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cycle-calendar fill-height"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"accent"},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-spacer'),(_vm.$refs.calendar)?_c('v-toolbar-title',[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"title",attrs:{"text":"","color":"accent"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")])]}}],null,false,2977448616),model:{value:(_vm.selectDay),callback:function ($$v) {_vm.selectDay=$$v},expression:"selectDay"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"zh-TW","day-format":function (date) {
              return _vm.moment(date).format('DD')
            }},on:{"change":function($event){_vm.selectDay = false}},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"accent"},on:{"click":_vm.next}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1),_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"type":"week","event-ripple":false,"event-name":"name","event-start":"starttime","event-end":"endtime","locale":"zh-Tw","event-text-color":"#165779","interval-format":function (interval) {
        return interval.time
      }},on:{"change":_vm.getEvents,"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startDragTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag,"mouseup:event":_vm.mouseUpEvent,"click:event":_vm.showEvent},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag($event)}},scopedSlots:_vm._u([{key:"event",fn:function(ref){
      var event = ref.event;
return [_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(_vm._s(event.name))])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":"","close-on-click":false,"max-width":"500px"},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('calendarEventCard',{attrs:{"type":"date"},on:{"remove":function($event){return _vm.removeEvent(_vm.selectedEvent.index, _vm.selectedEvent)},"save":function($event){return _vm.saveEvent(_vm.selectedEvent.index, _vm.selectedEvent)},"close":function($event){return _vm.closeEvent()}},model:{value:(_vm.selectedEvent),callback:function ($$v) {_vm.selectedEvent=$$v},expression:"selectedEvent"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }