<template>
  <v-card class="calendar-event-card" color="#FAFAFADD" flat>
    <v-card-text v-if="event">
      <v-row>
        <v-col cols="col">
          <v-row align="center">
            <v-col cols="12">
              <v-text-field
                v-model="event.name"
                label="名稱"
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="auto"> 開始時間 </v-col>
            <v-col cols="col">
              <input-time-picker v-model="starttime" dense hide-details />
            </v-col>
            <v-col cols="12">
              <CalendarEventAction v-model="event.startaction" />
            </v-col>
            <v-col cols="auto"> 結束時間 </v-col>
            <v-col cols="col">
              <input-time-picker v-model="endtime" dense hide-details />
            </v-col>
            <v-col cols="12">
              <CalendarEventAction v-model="event.endaction" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-list flat color="transparent" class="pa-0">
            <v-list-item-group v-model="event.color" color="transparent">
              <v-list-item
                v-for="(item, i) in colors"
                :key="i"
                :value="item"
                class="pa-0"
              >
                <v-list-item-content class="pt-0">
                  <v-sheet
                    :color="item"
                    elevation="1"
                    height="20"
                    width="20"
                    rounded
                  ></v-sheet>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-overlay
      :absolute="true"
      :z-index="300"
      :value="event && moment().isAfter(event.endtime) && type == 'date'"
    ></v-overlay>
    <v-card-actions
      v-if="moment().isAfter(event.endtime) && type == 'date'"
      class="justify-end"
    >
      <v-btn color="secondary" @click="closeEvent()" style="z-index: 302">
        關閉
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else class="justify-end">
      <v-btn outlined color="secondary" @click="removeEvent()"> 移除 </v-btn>
      <v-btn color="secondary" @click="saveEvent()"> 完成 </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import InputTimePicker from '@/components/InputTimePicker'
import CalendarEventAction from '@/views/school/schedule/calendarEventAction'
import { mapGetters } from 'vuex'

export default {
  name: 'CalendarEventCard',
  mixins: [],
  components: {
    InputTimePicker,
    CalendarEventAction
  },
  props: {
    value: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data: function () {
    return {
      event: this.value,
      colors: ['#63e8dcAA', '#f7d061AA', '#64c1f8AA', '#ff8492AA', '#ef95ffAA']
    }
  },
  computed: {
    ...mapGetters({}),
    starttime: {
      get: function () {
        if (!this.event || !this.event.starttime) {
          return moment().format('HH:mm')
        }
        return moment(this.event.starttime).format('HH:mm')
      },
      set: function (newValue) {
        this.event.starttime = moment(this.event.starttime)
          .hour(Number(moment(newValue, 'HH:mm').format('H')))
          .minute(Number(moment(newValue, 'HH:mm').format('m')))
          .format('YYYY-MM-DD HH:mm')
      }
    },
    endtime: {
      get: function () {
        if (!this.event || !this.event.endtime) {
          return moment().format('HH:mm')
        }
        return moment(this.event.endtime).format('HH:mm')
      },
      set: function (newValue) {
        this.event.endtime = moment(this.event.endtime)
          .hour(Number(moment(newValue, 'HH:mm').format('H')))
          .minute(Number(moment(newValue, 'HH:mm').format('m')))
          .format('YYYY-MM-DD HH:mm')
      }
    }
  },
  watch: {
    value(val) {
      this.event = val
    },
    starttime(val) {
      if (
        !moment(this.endtime, 'hh:mm').isSameOrAfter(
          moment(val, 'hh:mm'),
          'minute'
        )
      ) {
        this.endtime = val
      }
    },
    endtime(val) {
      if (
        !moment(val, 'hh:mm').isSameOrAfter(
          moment(this.starttime, 'hh:mm'),
          'minute'
        )
      ) {
        this.starttime = val
      }
    },
    'event.startaction'(val) {
      var startaction = {
        temperature: val.temperature || 28
      }
      var endaction = {
        temperature: this.event.endaction.temperature || 28
      }
      if (
        JSON.stringify(val) === JSON.stringify(startaction) &&
        JSON.stringify(this.event.endaction) !== JSON.stringify(endaction)
      ) {
        this.event.endaction = endaction
        return
      }

      startaction = {
        relaymode: val.relaymode || 0
      }
      endaction = {
        relaymode: this.event.endaction.relaymode || 0
      }
      if (
        JSON.stringify(val) === JSON.stringify(startaction) &&
        JSON.stringify(this.event.endaction) !== JSON.stringify(endaction)
      ) {
        this.event.endaction = endaction
      }
    },
    'event.endaction'(val) {
      var endaction = {
        temperature: val.temperature || 28
      }
      var startaction = {
        temperature: this.event.startaction.temperature || 28
      }
      if (
        JSON.stringify(this.event.startaction) !==
          JSON.stringify(startaction) &&
        JSON.stringify(val) === JSON.stringify(endaction)
      ) {
        this.event.startaction = startaction
      }

      startaction = {
        relaymode: this.event.startaction.relaymode || 0
      }
      endaction = {
        relaymode: this.event.endaction.relaymode || 0
      }
      if (
        JSON.stringify(this.event.startaction) !==
          JSON.stringify(startaction) &&
        JSON.stringify(val) === JSON.stringify(endaction)
      ) {
        this.event.startaction = startaction
      }
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    saveEvent() {
      // if (!moment(this.endtime, 'HH:mm').isAfter(moment(this.starttime, 'HH:mm').add(4, 'minute'))) {
      //   alert('起始時間需小於結束時間，且需間隔5分鐘以上。')
      //   return
      // }
      this.$emit('save', this.event)
    },
    removeEvent() {
      this.$emit('remove')
    },
    closeEvent() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-event-card ::v-deep {
  .v-list-item.v-list-item--active {
    .v-list-item__content {
      .v-sheet {
        border: 2px solid #092957 !important;
      }
    }
  }
}
</style>
