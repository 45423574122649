<template>
  <div class="cycle-calendar fill-height">
    <v-toolbar flat color="transparent">
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="$refs.calendar"> </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        target="_blank"
        :href="`${settings.publicPath}/files/快速匯入周循環.xlsx`"
        v-if="actionType === 'relay'"
      >
        <v-icon class="mr-1">mdi-file-download-outline</v-icon>下載空白時間表
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="clickImport"
        v-if="actionType === 'relay'"
      >
        <v-icon class="mr-1">mdi-file-upload-outline</v-icon>匯入時間表
      </v-btn>
      <v-file-input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        v-model="files"
        ref="files"
        @change="importFile"
        class="d-none"
      >
      </v-file-input>
    </v-toolbar>
    <v-calendar
      ref="calendar"
      color="primary"
      :events="events"
      type="week"
      :event-ripple="false"
      event-name="name"
      event-start="starttime"
      event-end="endtime"
      locale="zh-Tw"
      event-text-color="#165779"
      :interval-format="
        (interval) => {
          return interval.time
        }
      "
      @change="getEvents"
      @mousedown:event="startDrag"
      @mousedown:time="startDragTime"
      @mousemove:time="mouseMove"
      @mouseup:time="endDrag"
      @mouseup:event="mouseUpEvent"
      @mouseleave.native="cancelDrag"
      @click:event="showEvent"
    >
      <template v-slot:event="{ event }">
        <span class="subtitle-2 font-weight-bold">{{ event.name }}</span>
      </template>
    </v-calendar>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
      :close-on-click="false"
      max-width="500px"
    >
      <calendarEventCard
        v-model="selectedEvent"
        type="week"
        @remove="removeEvent(selectedEvent.index, selectedEvent)"
        @save="saveEvent(selectedEvent.index, selectedEvent)"
      />
    </v-menu>
  </div>
</template>

<script>
import settings from '@/settings'
import { mapActions } from 'vuex'
import calendarEventCard from '@/views/school/schedule/calendarEventCard'
import Schedule from '@/api/ems/Schedule'

export default {
  name: 'CycleCalendar',
  mixins: [],
  components: {
    calendarEventCard
  },
  props: {
    value: {
      type: Array
    },
    actionType: {
      type: String,
      default: 'relay'
    }
  },
  data: function () {
    return {
      settings: settings,
      autoIncrementKey: this.value.length,
      events: [],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      selectedOpen: false,
      selectedEvent: null,
      selectedElement: null,
      files: null
    }
  },
  computed: {
    formatEvents() {
      return this.events.map((event) => {
        let chooseday = moment(event.starttime).format('e')
        if (Number(chooseday) === 0) {
          chooseday = 7
        }
        return {
          chooseday: chooseday,
          color: event.color,
          endaction: event.endaction,
          endtime: moment(event.endtime).format('HH:mm'),
          name: event.name,
          startaction: event.startaction,
          starttime: moment(event.starttime).format('HH:mm')
        }
      })
    },
    defaultAction() {
      if (this.actionType === 'temperature') {
        return {
          startaction: {
            temperature: 28
          },
          endaction: {
            temperature: 28
          }
        }
      }
      return {
        startaction: {
          relaymode: 1
        },
        endaction: {
          relaymode: 0
        }
      }
    }
  },
  watch: {
    events: {
      deep: true,
      handler: function (val, oldVal) {
        this.commit()
      }
    }
  },
  created: function () {},
  mounted: function () {
    this.$refs.calendar.scrollToTime('06:00')
  },
  destroyed() {},
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show'
    }),
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event, eventParsed }) {
      if (this.selectedOpen) {
        return
      }
      const open = () => {
        this.selectedEvent = JSON.parse(JSON.stringify(event))
        this.selectedEvent.index = eventParsed.index
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        // this.selectedOpen = false
        setTimeout(open, 100)
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startDragTime(tms) {
      if (this.selectedOpen) {
        return
      }
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start
        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)
        this.autoIncrementKey++
        let chooseday = moment(this.createStart).format('e')
        if (Number(chooseday) === 0) {
          chooseday = 7
        }
        this.createEvent = {
          key: this.autoIncrementKey,
          color: '#63e8dcAA',
          name: `排程 ${this.autoIncrementKey}`,
          starttime: this.createStart,
          endtime: this.createStart + 1000 * 60 * 60,
          chooseday: chooseday,
          timed: true,
          ...this.defaultAction
        }
        this.checkEvent(this.createEvent.starttime, this.createEvent.endtime)
        this.events.push(this.createEvent)
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        console.log('dragEvent')
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)
        this.createEvent.starttime = moment(min).format('YYYY-MM-DD HH:mm')
        this.createEvent.endtime = moment(max).format('YYYY-MM-DD HH:mm')
        if (moment(max).isAfter(this.createEvent.starttime, 'day')) {
          // 禁止跨天
          this.createEvent.endtime = moment(min)
            .endOf('day')
            .format('YYYY-MM-DD HH:mm')
        }
      }
    },
    endDrag() {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    mouseUpEvent({ event, eventParsed }) {
      this.checkEvent(
        moment(eventParsed.startTimestampIdentifier, 'YYYYMMDDHHmm').format(
          'YYYY-MM-DD HH:mm'
        ),
        moment(eventParsed.endTimestampIdentifier, 'YYYYMMDDHHmm').format(
          'YYYY-MM-DD HH:mm'
        )
      )
    },
    checkEvent(starttime, endtime) {
      // 後蓋前，移除同時段排程內容
      console.log('checkEvent')
      this.events = this.events.filter((event) => {
        if (moment(starttime).isBetween(event.starttime, event.endtime)) {
          return false
        }
        if (moment(endtime).isBetween(event.starttime, event.endtime)) {
          return false
        }
        if (moment(event.starttime).isBetween(starttime, endtime)) {
          return false
        }
        if (moment(event.endtime).isBetween(starttime, endtime)) {
          return false
        }
        return true
      })
    },
    cancelDrag() {
      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime()
    },
    getEvents({ start, end }) {
      // this.events = this.value || []
      this.events = this.value.map((item) => {
        let chooseday = item.chooseday
        if (Number(chooseday) === 7) {
          chooseday = 0
        }
        item.starttime = moment(item.starttime, 'HH:mm')
          .weekday(chooseday)
          .format('YYYY-MM-DD HH:mm')
        item.endtime = moment(item.endtime, 'HH:mm')
          .weekday(chooseday)
          .format('YYYY-MM-DD HH:mm')
        return item
      })
      this.$refs.calendar.checkChange()
    },
    saveEvent(index, event) {
      this.events.splice(index, 1)
      this.checkEvent(this.selectedEvent.starttime, this.selectedEvent.endtime)
      this.events.push(this.selectedEvent)
      this.selectedEvent = null
      this.selectedOpen = false
    },
    removeEvent(index) {
      this.events.splice(index, 1)
      this.selectedOpen = false
    },
    commit() {
      this.$emit('input', this.formatEvents)
    },
    clickImport() {
      this.files = null
      this.$nextTick(() => {
        this.$refs.files.$refs.input.click()
      })
    },
    importFile(File) {
      if (!File) {
        return
      }
      const formData = new FormData()
      formData.append('excel', this.files)
      Schedule.importFile(formData)
        .then((resp) => {
          this.importFileResponseData(resp.data)
        })
        .catch(() => {
          this.showMsg({
            message: `匯入失敗`,
            color: 'error',
            closable: true,
            time: 2000
          })
        })
    },
    importFileResponseData(data) {
      this.events = data.action.map((item) => {
        item.color = '#63e8dcAA'
        let chooseday = item.chooseday
        if (Number(chooseday) === 7) {
          chooseday = 0
        }
        item.starttime = moment(item.starttime, 'HH:mm')
          .weekday(chooseday)
          .format('YYYY-MM-DD HH:mm')
        item.endtime = moment(item.endtime, 'HH:mm')
          .weekday(chooseday)
          .format('YYYY-MM-DD HH:mm')

        for (var startActionName in item.startaction) {
          if (
            item.startaction[startActionName] === null ||
            item.startaction[startActionName] === undefined
          ) {
            delete item.startaction[startActionName]
          }
        }

        for (var endActionName in item.endaction) {
          if (
            item.endaction[endActionName] === null ||
            item.endaction[endActionName] === undefined
          ) {
            delete item.endaction[endActionName]
          }
        }

        return item
      })
      this.showMsg({
        message: `匯入完成`,
        closable: true,
        time: 2000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$event-left-line-width: 5px;

.cycle-calendar ::v-deep {
  .v-calendar {
    height: calc(100vh - 360px);
    min-height: 300px;
  }
  .v-calendar-daily_head-day-label {
    display: none;
  }
  .v-calendar-daily_head-weekday {
    padding: 10px 0;
    font-size: 1rem;
    color: #889bb7 !important;
  }
  .v-calendar-events {
    .v-event-timed-container {
      margin-right: 0;
      .v-event-timed {
        border-color: transparent !important;
        border-radius: 0;
      }
    }
  }
}
</style>
