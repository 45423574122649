<template>
  <v-sheet rounded class="tag-list pa-5">
    <v-list>
      <v-list-item
        v-for="groupId in selected"
        :key="groupId"
        class="rounded mb-2"
      >
        <v-list-item-content class="accent--text font-weight-bold">
          {{ getGroupNameById(groupId) }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            small
            class="blue-grey lighten-3"
            @click="removeSelected(groupId)"
          >
            <v-icon color="white" dense>mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-btn color="primary" @click="drawer = true"> 新增對象 </v-btn>
    <v-navigation-drawer v-model="drawer" fixed right temporary width="70%">
      <v-container>
        <div class="text-right">
          <v-btn text icon @click="drawer = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <span class="title accent--text font-weight-bold">群組</span>
          <span
            class="error--text text-caption ml-2"
            v-if="cardReaderGroup.length"
            >* 一般冷氣群組和既設冷氣群組不能加入同一個排程</span
          >
        </div>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <GroupAppliedHint />
        </div>
        <v-list two-line>
          <v-list-item-group
            :value="selected"
            @change="selectGroup"
            active-class="primary white--text"
            multiple
          >
            <div v-if="cardReaderGroup.length" class="my-2">一般冷氣群組</div>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                lg="4"
                v-for="item in filteredGroup"
                :key="item.id"
              >
                <v-list-item :value="item.id" class="rounded">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                      <GroupApply :group="item" />
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        color="white"
                        :input-value="active"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-col>
            </v-row>
            <v-divider v-if="cardReaderGroup.length" class="my-5"></v-divider>
            <div v-if="cardReaderGroup.length" class="mb-2">既設冷氣群組</div>
            <v-row v-if="cardReaderGroup.length">
              <v-col
                cols="12"
                sm="6"
                lg="4"
                v-for="item in cardReaderGroup"
                :key="item.id"
              >
                <v-list-item :value="item.id" class="rounded">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                      <GroupApply :group="item" />
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        color="white"
                        :input-value="active"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list-item-group>
        </v-list>
      </v-container>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GroupAppliedHint from '@/views/school/groupApplied/Hint'
import GroupApply from '@/views/school/groupApplied/Apply'

export default {
  name: undefined,
  mixins: [],
  components: { GroupAppliedHint, GroupApply },
  props: {
    value: {
      type: Array
    },
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      drawer: false,
      selected: this.value
    }
  },
  computed: {
    ...mapGetters({
      groups: 'groups/groups'
    }),
    filteredGroup() {
      if (!this.groups) {
        return []
      }
      return this.groups.filter(({ countMap, type }) => {
        return (
          countMap.AIRCONDITIONER > 0 ||
          (this.$route.path.includes('relay') && ['IR_AIR_CON'].includes(type))
        )
      })
    },
    cardReaderGroup() {
      if (!this.groups) {
        return []
      }
      return this.$route.path.includes('relay')
        ? this.groups.filter(({ type }) => type === 'CARD_READER')
        : []
    }
  },
  watch: {
    value(val) {
      this.selected = val
    },
    selected() {
      this.commit()
    }
  },
  created: function () {},
  mounted: function () {
    this.getGroups(this.tagId)
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getGroups: 'groups/getGroups'
    }),
    getGroupNameById(id) {
      if (!this.groups || !Array.isArray(this.groups)) {
        return null
      }
      const group = this.groups.find((item) => item.id === id)
      return group ? group.name : null
    },
    removeSelected(id) {
      this.selected = this.selected.filter((groupId) => groupId !== id)
    },
    commit() {
      this.$emit('input', this.selected)
    },
    selectGroup(curr) {
      if (!curr.length) this.selected = []
      let currentLastGroup = this.groups.find(({ id }) => id === curr.at(-1))
      let previousLastGroup = this.groups.find(
        ({ id }) => id === this.selected.at(-1)
      )
      if (previousLastGroup) {
        // IR 冷氣群組和一般冷氣群組可以加入同一個排程
        // type: IR_AIR_CON & AIR_CON
        if (
          currentLastGroup.type.includes('AIR_CON') &&
          previousLastGroup.type.includes('AIR_CON')
        )
          this.selected = curr
        else if (currentLastGroup.type !== previousLastGroup.type)
          this.selected = [currentLastGroup.id]
        else this.selected = curr
      } else this.selected = curr
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-list ::v-deep {
  background-color: #ffffff88;
  .v-list {
    background-color: transparent;

    .v-list-item {
      background-color: #c9e3f3;
    }
  }
}
</style>
