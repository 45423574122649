import settings from '@/settings'

/**
 * 排程
 */
class Schedule {
  /**
   * 列表
   */
  index(schoolId) {
    return axios.get(settings.api.fullPath + `/schedule/school/${schoolId}`)
  }

  /**
   * 新增
   */
  create(schoolId, data) {
    return axios.post(settings.api.fullPath + `/schedule/${schoolId}`, data)
  }

  /**
   * 修改
   */
  update(scheduleId, data) {
    return axios.put(settings.api.fullPath + `/schedule/${scheduleId}`, data)
  }

  /**
   * 刪除
   */
  delete(scheduleId) {
    return axios.delete(settings.api.fullPath + `/schedule/${scheduleId}`)
  }

  /**
   * 明細
   */
  show(scheduleId) {
    return axios.get(settings.api.fullPath + `/schedule/${scheduleId}`)
  }

  /**
   * 批次上傳
   */
  importFile(formData) {
    return axios.post(settings.api.fullPath + `/schedule/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

var schedule = new Schedule()
export default schedule
