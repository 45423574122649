<template>
  <div class="cycle-calendar fill-height">
    <v-toolbar flat color="transparent">
      <v-btn fab text small color="accent" @click="prev">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="$refs.calendar">
        <v-menu v-model="selectDay" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="title" text v-bind="attrs" v-on="on" color="accent">
              {{ $refs.calendar.title }}
            </v-btn>
          </template>
          <v-date-picker
            v-model="focus"
            no-title
            scrollable
            locale="zh-TW"
            :day-format="
              (date) => {
                return moment(date).format('DD')
              }
            "
            @change="selectDay = false"
          ></v-date-picker>
        </v-menu>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn fab text small color="accent" @click="next">
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
    </v-toolbar>
    <v-calendar
      ref="calendar"
      v-model="focus"
      color="primary"
      :events="events"
      type="week"
      :event-ripple="false"
      event-name="name"
      event-start="starttime"
      event-end="endtime"
      locale="zh-Tw"
      event-text-color="#165779"
      :interval-format="
        (interval) => {
          return interval.time
        }
      "
      @change="getEvents"
      @mousedown:event="startDrag"
      @mousedown:time="startDragTime"
      @mousemove:time="mouseMove"
      @mouseup:time="endDrag"
      @mouseup:event="mouseUpEvent"
      @mouseleave.native="cancelDrag"
      @click:event="showEvent"
    >
      <template v-slot:event="{ event }">
        <span class="subtitle-2 font-weight-bold">{{ event.name }}</span>
      </template>
    </v-calendar>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
      :close-on-click="false"
      max-width="500px"
    >
      <calendarEventCard
        v-model="selectedEvent"
        type="date"
        @remove="removeEvent(selectedEvent.index, selectedEvent)"
        @save="saveEvent(selectedEvent.index, selectedEvent)"
        @close="closeEvent()"
      />
    </v-menu>
  </div>
</template>

<script>
import calendarEventCard from '@/views/school/schedule/calendarEventCard'

export default {
  name: 'SingleDayCalendar',
  mixins: [],
  components: {
    calendarEventCard
  },
  props: {
    value: {
      type: Array
    },
    actionType: {
      type: String,
      default: 'relay'
    }
  },
  data: function () {
    return {
      autoIncrementKey: this.value.length,
      selectDay: false,
      focus: '',
      events: [],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      selectedOpen: false,
      selectedEvent: null,
      selectedElement: null
    }
  },
  computed: {
    formatEvents() {
      return this.events.map((event) => {
        return {
          name: event.name,
          color: event.color,
          specialday: moment(event.starttime).format('YYYY-MM-DD'),
          starttime: moment(event.starttime).format('HH:mm'),
          startaction: event.startaction,
          endtime: moment(event.endtime).format('HH:mm'),
          endaction: event.endaction
        }
      })
    },
    defaultAction() {
      if (this.actionType === 'temperature') {
        return {
          startaction: {
            temperature: 28
          },
          endaction: {
            temperature: 28
          }
        }
      }
      return {
        startaction: {
          relaymode: 1
        },
        endaction: {
          relaymode: 0
        }
      }
    }
  },
  watch: {
    value(val, oldVal) {},
    events: {
      handler: function (val, oldVal) {
        console.log('watch events', JSON.parse(JSON.stringify(val)))
        this.commit()
      },
      deep: true
    }
  },
  created: function () {},
  mounted: function () {
    this.$refs.calendar.scrollToTime('06:00')
  },
  destroyed() {},
  methods: {
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event, eventParsed }) {
      if (this.selectedOpen) {
        return
      }
      const open = () => {
        this.selectedEvent = JSON.parse(JSON.stringify(event))
        this.selectedEvent.index = eventParsed.index
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        // this.selectedOpen = false
        setTimeout(open, 100)
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startDragTime(tms) {
      if (this.selectedOpen) {
        return
      }
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)
        this.autoIncrementKey++
        this.createEvent = {
          key: this.autoIncrementKey,
          color: '#63e8dcAA',
          name: `排程 ${this.autoIncrementKey}`,
          starttime: this.createStart,
          endtime: this.createStart + 1000 * 60 * 60,
          timed: true,
          ...this.defaultAction
        }
        this.checkEvent(this.createEvent.starttime, this.createEvent.endtime)
        // 過去時間不可新增
        if (moment().isAfter(this.createEvent.starttime)) {
          return
        }
        this.events.push(this.createEvent)
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        console.log('dragEvent')
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)
        this.createEvent.starttime = moment(min).format('YYYY-MM-DD HH:mm')
        this.createEvent.endtime = moment(max).format('YYYY-MM-DD HH:mm')
        if (moment(max).isAfter(this.createEvent.starttime, 'day')) {
          // 禁止跨天
          this.createEvent.endtime = moment(min)
            .endOf('day')
            .format('YYYY-MM-DD HH:mm')
        }
      }
    },
    endDrag() {
      console.log('endDrag')
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    mouseUpEvent({ event, eventParsed }) {
      this.checkEvent(
        moment(eventParsed.startTimestampIdentifier, 'YYYYMMDDHHmm').format(
          'YYYY-MM-DD HH:mm'
        ),
        moment(eventParsed.endTimestampIdentifier, 'YYYYMMDDHHmm').format(
          'YYYY-MM-DD HH:mm'
        )
      )
    },
    checkEvent(starttime, endtime) {
      // 後蓋前，移除同時段排程內容
      console.log('checkEvent')
      this.events = this.events.filter((event) => {
        if (moment(starttime).isBetween(event.starttime, event.endtime)) {
          return false
        }
        if (moment(endtime).isBetween(event.starttime, event.endtime)) {
          return false
        }
        if (moment(event.starttime).isBetween(starttime, endtime)) {
          return false
        }
        if (moment(event.endtime).isBetween(starttime, endtime)) {
          return false
        }
        return true
      })
    },
    cancelDrag() {
      console.log('cancelDrag')
      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime()
    },
    getEvents({ start, end }) {
      this.events = this.value.map((item) => {
        item.starttime = moment(
          `${item.specialday} ${item.starttime}`,
          'YYYY-MM-DD HH:mm'
        ).format('YYYY-MM-DD HH:mm')
        item.endtime = moment(
          `${item.specialday} ${item.endtime}`,
          'YYYY-MM-DD HH:mm'
        ).format('YYYY-MM-DD HH:mm')
        return item
      })
    },
    saveEvent(index, event) {
      this.events.splice(
        index,
        1,
        JSON.parse(JSON.stringify(this.selectedEvent))
      )
      this.selectedEvent = null
      this.selectedOpen = false
    },
    removeEvent(index) {
      this.events.splice(index, 1)
      this.selectedOpen = false
    },
    closeEvent() {
      this.selectedOpen = false
    },
    commit() {
      this.$emit('input', this.formatEvents)
    }
  }
}
</script>

<style lang="scss" scoped>
$event-left-line-width: 5px;

.cycle-calendar ::v-deep {
  .v-calendar {
    height: calc(100vh - 360px);
    min-height: 300px;
  }
  .v-calendar-daily_head-weekday {
    padding: 10px 0;
    font-size: 1rem;
    color: #889bb7 !important;
  }
  .v-calendar-events {
    .v-event-timed-container {
      margin-right: 0;
      .v-event-timed {
        border-color: transparent !important;
        border-radius: 0;
      }
    }
  }
}
</style>
