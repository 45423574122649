<template>
  <div>
    <v-icon
      small
      color="primary"
      v-if="['AIR_CON', 'IR_AIR_CON', 'CARD_READER'].includes(group.type)"
      >mdi-air-conditioner</v-icon
    >
    <v-icon small color="teal darken-2" v-if="group.isSetToSchedule"
      >mdi-calendar-clock</v-icon
    >
    <v-icon small color="orange darken-4" v-if="group.isSetToOffloading"
      >mdi-package-variant</v-icon
    >
    <v-icon small color="purple" v-if="group.isSetToOpenAdr"
      >mdi-alpha-a-box</v-icon
    >
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      required: true
    }
  }
}
</script>
