<template>
  <v-row align="center">
    <v-col cols="auto" style="width: 6.5rem">
      <v-select
        :items="hourItems"
        filled
        v-model="hour"
        :dense="dense"
        :hide-details="hideDetails"
      >
      </v-select>
    </v-col>
    <v-col cols="auto" class="pa-0 pb-6">
      <span class="font-weight-bold">:</span>
    </v-col>
    <v-col cols="auto" style="width: 6.5rem">
      <v-select
        :items="minuteItems"
        filled
        v-model="minute"
        :dense="dense"
        :hide-details="hideDetails"
      >
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TimePicker',
  mixins: [],
  components: {},
  props: {
    value: {
      type: String
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      hour: 0,
      minute: 0
    }
  },
  computed: {
    hourItems() {
      const item = []
      for (var i = 0; i <= 23; i++) {
        item.push({
          text: String(i).padStart(2, '0'),
          value: String(i).padStart(2, '0')
        })
      }
      return item
    },
    minuteItems() {
      const item = []
      for (var i = 0; i <= 59; i++) {
        item.push({
          text: String(i).padStart(2, '0'),
          value: String(i).padStart(2, '0')
        })
      }
      return item
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.hour = moment(val, 'HH:mm').format('HH')
        this.minute = moment(val, 'HH:mm').format('mm')
      } else {
        this.hour = '00'
        this.minute = '00'
      }
    },
    hour: function () {
      this.commit()
    },
    minute: function () {
      this.commit()
    }
  },
  created: function () {},
  mounted: function () {
    this.hour = moment(this.value, 'HH:mm').format('HH')
    this.minute = moment(this.value, 'HH:mm').format('mm')
  },
  destroyed() {},
  methods: {
    commit() {
      this.$emit('input', `${this.hour}:${this.minute}`)
    }
  }
}
</script>

<style lang="scss" scoped></style>
